import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"



const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <section className="info lg:pt-8">
      <div className="infowrap">
        <div className="info-text">
          <div className="flex align-center margin-bottom-md color-contrast-medium">
          <StaticImage src="../images/bailey-latimer--latimer-design.jpg" alt="Bailey Latimer – Latimer Design" aspectRatio={1/1} width={500}/>
          </div>
          <p class="color-contrast-high">Hi there, I'm Bailey Latimer, an award-winning <span class="color-contrast-higher">designer who codes</span> based in San Diego, CA.</p>
          {/* <p>I'm currently a Creative Director at <Link className="link-fx-5"  to="https://gosun.co" target="_blank" rel="noopener noreferrer nofollow">GoSun</Link> and founder at <Link className="link-fx-5"  to="https://www.linkedin.com/company/latimer-design" target="_blank" rel="noopener noreferrer nofollow">Latimer Design</Link>.</p> */}

          <p class="color-contrast-high">
          I work with a broad range of <span class="color-contrast-higher">web 3</span> and <span class="color-contrast-higher">e-commerce</span> clients across a mix of disciplines that includes <span class="color-contrast-higher">web development, web design, creative direction, and graphic design.</span> 
          </p>
{/* 
          <p>I graduated from the <Link className="link-fx-5"  to="https://uc.edu/" target="_blank" rel="noopener noreferrer nofollow">University of Cincinnati</Link>&nbsp;with a BA in Marketing & Information Systems.&nbsp;Previously, I worked hybrid designer/developer roles at <Link className="link-fx-5"  to="https://crown.com" target="_blank" rel="noopener noreferrer nofollow">Crown</Link> and <Link className="link-fx-5"  to="https://uc.edu" target="_blank" rel="noopener noreferrer nofollow">UC</Link>.
          </p> */}
          <p class="color-contrast-high">I've <span class="color-contrast-higher">designed</span> and <span class="color-contrast-higher">developed</span> countless custom sites using <span class="color-contrast-higher">Figma</span>, <span class="color-contrast-higher">React</span>, and <span class="color-contrast-higher">Shopify.</span> Whether you want a traditional or <span class="color-contrast-higher">headless e-commerce</span> experience, I've got you covered.</p>

          <p class="color-contrast-high">When not working, you can catch me golfing, surfing, and snapping photos ✌️.</p>
 
          <div className="col rand-title hide-mobile margin-top-lg">
            <h2 >Need a logo or website? <br></br><Link className="link-fx-5 margin-top-xs"  to="/contact/"> Let's work together ➞ </Link></h2>
          </div>

          

          <div className="info-links">
              <div><Link className="link-fx-5"  to="/workspace">Workspace</Link></div>
              <div><Link className="link-fx-5"  to="mailto:bailey@latimer.me" target="_blank" rel="noopener noreferrer nofollow">Email</Link></div>
              <div><Link className="link-fx-5"  to="../../../files/Latimer-Bailey_Resume.pdf" target="_blank" rel="noopener noreferrer nofollow">Resume</Link></div>
              <div><Link className="link-fx-5"  to="https://www.linkedin.com/in/baileylatimer/" target="_blank" rel="noopener noreferrer nofollow">LinkedIn</Link></div>
              <div><Link className="link-fx-5"  to="https://dribbble.com/latimerdesign" target="_blank" rel="noopener noreferrer nofollow">Dribbble</Link></div>
              <div><Link className="link-fx-5"  to="https://www.instagram.com/latimer2k/" target="_blank" rel="noopener noreferrer nofollow">Instagram</Link></div>
            </div>

          <Link className="link-fx-5 margin-top-xl"  to="/">← Go back</Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage
